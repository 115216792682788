.header {
  // vertical-align: middle;
  margin-bottom: 0;
  padding: 6px 12px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  background-color: var(--color-nav-blue);
  margin-left: 20px;

  // width: 25%;
  gap: 10px;

  .nameContainer {
    flex: 1;
    display: flex;
    flex-direction: column;

    .name {
      p {
        text-transform: capitalize;
        color: white;
        font-weight: 500;
        font-size: 15px;
        text-align: center;
      }
    }
  }

  .menuContainer {
    flex: 0 0 45px;
    display: flex;
    justify-content: flex-end;
  }

  &:hover {
    cursor: pointer;
  }
}
