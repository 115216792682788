.profileContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  order: 4;
  margin-right: 15px;
  margin-left: 15px;

  .dropdown {
    @media (min-width: 768px) {
      display: block;
    }

    .dropdownLink {
      color: var(--Light-Gray-03);
      font-family: "Roboto";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding-left: 24px;
      padding-right: 24px;
      padding-bottom: 18px;

      &:hover {
        text-decoration: underline;
      }
    }

    .dropdownLink:last-child {
      border-top: solid 0.5px var(--color-light-gray);
      margin-bottom: 20px;
      margin-right: 24px;
      margin-left: 24px;
      padding-right: 0;
      padding-left: 0;
      padding-top: 12px;
    }

    .dropdownFirstCard {
      font-family: "Roboto", sans-serif;
      padding: 0px;
      display: block;
      width: 100%;
      height: 100%;
      text-align: left;
      padding: 20px 35px;
      font-size: 16px;

      font-weight: 600;

      &:hover {
        color: var(--color-light-gray-4);
        text-decoration: underline;
      }
    }

    .profile {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin-bottom: 8px;

      .thumbnail {
        width: 32px;
        height: 32px;
        overflow: hidden;
        border-radius: 50%;

        img {
          width: 100% !important;
          height: 100% !important;
          object-fit: cover;
        }
      }

      .dropdownThumbnail {
        width: 48px;
        height: 48px;
        overflow: hidden;
        border-radius: 50%;

        img {
          width: 100% !important;
          height: 100% !important;
          object-fit: cover;
        }
      }

      .info {
        margin-right: 10px;
        margin-left: 10px;

        .name {
          margin: 0;
          font-weight: 400;
          font-size: 15px;
          text-transform: capitalize;
          color: #fff;

          &:hover {
            transition: all ease-in-out 300ms;
          }
        }

        .type {
          margin: 0;
          font-size: 12px;
          text-transform: capitalize;
          color: var(--color-blue);
        }
      }
    }
  }
}
