html,
body {
  box-sizing: content-box;
  padding: 0;
  margin: 0;
  -webkit-text-size-adjust: 100%;
}

// @media (-webkit-min-device-pixel-ratio: 2){
//   html{
//     font-size: 20px;
//   }
// }
