html,
body {
  scroll-behavior: auto !important;
  // overflow-y: scroll;
  height: 100vh;
  height: 100dvh;
  max-height: stretch;
  // max-height: -webkit-fill-available;
  -webkit-overflow-scrolling: touch;
  margin: 0;
}

body {
  // overflow-y: visible;
  position: relative;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-gutter: stable;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#notification-popover {
  position: fixed !important;
  top: 45px !important;
  padding: 0;
}

html.noscroll {
  position: fixed;
  overflow-y: scroll;
  width: 100%;
}
// Light mode colors
:root {
  --color-blue: #1675e0;
  --color-dark-blue: #273647;
  --color-black: #282828;
  --color-white: white;
  --color-onboarding-background: white;
  --color-dark-red: #881c24;
  --color-pink: #f8d7da;
  --color-background: #282828;
  --color-text: #575757;
  --color-success: #4caf50;
  --color-card-border: #dddfe2; // #e7e7e9;
  --color-loader-center: white;
  --color-loader-inner: #a7cced;
  --color-loader-outer: #0077b8;
  --color-hnc-orange: #ff7d26;
  --color-nav-blue: #0077b8;
  --color-red: #ff0000;
  --color-island: pink;
  --color-ocean-bg: #97d0ff;
  --color-sidemenu: #f2f3f5;
  --color-settings: white;
  --color-hover: #d6d9dc;
  --color-desktop-animation-bg: #97d0ff;
  --color-mobile-animation-gradient-start: #74b0ff;
  --color-mobile-animation-gradient-end: #003278;
  --color-light-gray-1: #e0e0e0;
  --color-light-gray-3: #747474;
  --color-light-gray-4: #4d4d4d;
  --color-light-gray: #8e8e8e;
  --color-off-white: #fafafa;
  --color-mid-gray: #36393b;
  --color-txt-dark-gray: #23272a;
  --color-txt-blue-01: #0077b8;
  --color-editor-black: "#222222";
}

// Light mode colors
[data-theme="dark"] {
  --color-blue: #1675e0;
  --color-dark-blue: #273647;
  --color-black: #282828;
  --color-white: white;
  --color-onboarding-background: white;
  --color-dark-red: #881c24;
  --color-pink: #f8d7da;
  --color-background: #282828;
  --color-text: #575757;
  --color-success: #4caf50;
  --color-card-border: #dddfe2; // #e7e7e9;
  --color-loader-center: white;
  --color-loader-inner: #a7cced;
  --color-loader-outer: #0077b8;
  --color-orange: #ff7d26;
  --color-hnc-orange: #ff7d26;
  --color-nav-blue: #0077b8;
  --color-red: #ff0000;
  --color-island: pink;
  --color-ocean-bg: #97d0ff;
  --color-sidemenu: #f2f3f5;
  --color-settings: white;
  --color-hover: #d6d9dc;
  --color-desktop-animation-bg: #97d0ff;
  --color-mobile-animation-gradient-start: #74b0ff;
  --color-mobile-animation-gradient-end: #003278;
  --color-light-gray-1: #e0e0e0;
  --color-light-gray-3: #747474;
  --color-light-gray-4: #4d4d4d;
  --color-light-gray: #8e8e8e;
  --color-off-white: #fafafa;
  --color-mid-gray: #36393b;
  --color-txt-dark-gray: #23272a;
  --color-txt-blue-01: #0077b8;
  --color-editor-black: "#222222";
}

// dark mode colors
// [data-theme="dark"] {
//   --color-ocean-bg: #154d83;
//   --color-blue: #008dde;
//   --color-dark-blue: #273647;
//   --color-black: #d3d3d3;
//   --color-text: #d3d3d3;
//   --color-white: #2c2f33;
//   --color-onboarding-background: #24272a;
//   --color-dark-red: #881c24;
//   --color-pink: #f8d7da;
//   --color-background: #282828;
//   --color-success: #4caf50;
//   --color-card-border: #fff;
//   --color-loader-center: #23272a;
//   --color-loader-inner: #a7cced;
//   --color-loader-outer: #00adef;
//   --color-orange: #ff7d26;
//   --color-nav-blue: #00adef;
//   --color-red: #ff0000;
//   --color-island: yellow;
//   --color-ocean-bg: #154d83;
//   --color-sidemenu: #23272a;
//   --color-settings: #2c2f33;
//   --color-hover: #2e3235;
//   --color-desktop-animation-bg: #154d83;
//   --color-mobile-animation-gradient-start: #1c5094;
//   --color-mobile-animation-gradient-end: #000000;
//   --color-light-gray-1: #e0e0e0;
//   --color-light-gray-3: #747474;
//   --color-light-gray-4: #4d4d4d;
//   --color-light-gray: #8e8e8e;
//   --color-off-white: #fafafa;
//   --color-mid-gray: #36393b;
//   --color-txt-dark-gray: #23272a;
//   --color-txt-blue-01: #0077b8;
//   --color-editor-black: "#222222";
// }

#monaco-editor {
  width: 100%;
  height: 600px;
  border: 1px solid #ccc;
}
.yRemoteSelection {
  background-color: var(--color-blue);
  height: 50px;
  width: 50px;
}

.yRemoteSelectionHead {
  position: absolute;
  border-left: var(--color-blue) solid 2px;
  border-top: var(--color-blue) solid 2px;
  border-bottom: var(--color-blue) solid 2px;
  height: 100%;
  box-sizing: border-box;
}
// .yRemoteSelectionHead::after {
//   position: absolute;
//   content: ' ';
//   border: 3px solid orange;
//   border-radius: 4px;
//   left: -4px;
//   top: -5px;
// }

.hustlencode-editor {
  * {
    font-family: monospace !important;
  }
}

#notification-toaster {
  .rs-notification-content {
    .rs-notification-description {
      margin: 0 !important;
    }
  }
}

.hnc-chat-button {
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 0px;
  right: 0px;
  z-index: 9999;
  background-color: red;
}

/*
.chat-shaft{
  
}
*/

.chat-shaft--offline {
  --chat-bg-color: var(--color-white) !important;
}
#chatra__iframe {
  background-color: red;
  .chat-shaft {
    background-color: var(--color-white) !important;
  }
}

.splitViewDragBar.Horizontal {
  padding: 0 6px !important;
  background-color: var(--color-txt-dark-gray);

  @media (pointer: none), (pointer: coarse) {
    padding: 0 10px !important;
  }
}

.splitViewDragBar.Vertical {
  padding: 6px 0 !important;
  background-color: var(--color-txt-dark-gray);

  @media (pointer: none), (pointer: coarse) {
    padding: 10px 0 !important;
  }
}

.splitViewDragBarHighlight.Vertical {
  background-color: var(--color-light-gray) !important;
  width: 48px !important;
  height: 4px !important;
}
.splitViewDragBarHighlight.Horizontal {
  background-color: var(--color-light-gray) !important;
  height: 48px !important;
  width: 6px !important;
}
