.inputGroup {
  display: flex;
  // display: none;
  // flex: 0 0 150px;

  .group {
    border-color: #e0e0e0;
    border-radius: 30px;
    color: var(--color-light-gray-1);

    span {
      border-top-left-radius: 30px !important;
      border-bottom-left-radius: 30px !important;
      background-color: var(--color-off-white);
      padding-left: 24px;
    }

    input {
      border-top-right-radius: 30px !important;
      border-bottom-right-radius: 30px !important;
      background-color: var(--color-off-white);
      padding-left: 0px;
    }
    input::placeholder {
      color: "gray";
      font-family: "Roboto";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

// @media (max-width: 890px) {
//   .inputGroup {
//     display: flex;
//     width: 30%;
//     order: 0;
//   }
// }

.results {
  display: flex;
  flex-direction: column;

  /**
   * Bug Fixed -
   * Issue was that the media query was preventing styles to 
   * be applied to widths below 992px
   */
  background-color: var(--color-white);
  position: absolute;
  top: 40px;
  z-index: 99;
  margin-top: 5px;
  border: none;
  border-radius: 4px;
  padding-bottom: 10px;
  box-shadow: rgba(28, 28, 28, 0.2) 0px 2px 4px 0px;
  border: solid 1px rgb(237, 239, 241);

  .spinner {
    padding: 12px 6px;
    display: flex;
    justify-content: center;
    border-radius: 0px;
  }

  .searching {
    display: flex;
    justify-content: flex-start;

    a,
    p {
      flex: 1;
      padding: 12px 16px 12px 16px;
      text-decoration: none;
      // color: var(--color-black);
      font-size: 14px;
    }
  }

  .link {
    display: flex;
    justify-content: flex-start;
    padding: 10px 16px 0px 16px;

    p {
      font-size: 14px;
    }
  }
}

.searchResult {
  display: flex;
  align-items: center;
  padding: 7px 16px;
  gap: 10px;

  &:hover {
    cursor: pointer;
    background-color: var(--color-black);

    p:first-child {
      color: var(--color-white);
    }
  }
}

.searchAllContainer {
  display: flex;
  align-items: center;
  padding: 7px 16px;
  gap: 3px;

  .iconSearch {
    font-size: 15px;
    padding: 0px 15px 0px 0px;
    color: black;
  }

  .searchContent {
    p {
      font-size: 15px;
      color: black;
    }
  }

  &:hover {
    cursor: pointer;
    background-color: rgba(182, 181, 181, 0.9);
  }
}
